import '/src/scss/app.scss';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

(function(window, document, undefined){
  const menuButton = document.querySelector('#menu-button');
  const menuContainer = document.querySelector('#menu-container');

  menuButton.addEventListener('click', e => {
    e.preventDefault();
    menuButton.classList.toggle('is-active');
    menuContainer.classList.toggle('is-open');
  })
}(window, document));
